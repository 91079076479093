<template>
  <v-card
    outlined
    class="fill-height my-2 rounded-lg rounded-bl-0 d-flex flex-column"
    :to="{
      name: routeName,
      params: { postName: post.slug, type: post.type }
    }"
  >
    <v-img
      height="190"
      max-height="190"
      :src="post.featured_image_url"
      :alt="`Post: ${post.title.rendered}`"
    ></v-img>

    <v-card-subtitle
      class="primary--text font-weight-bold text-uppercase"
      v-if="subtitle && subtitle.length"
      ><v-chip small color="primary">{{ subtitle }}</v-chip>
    </v-card-subtitle>
    <v-card-subtitle
      class="pt-4 pb-0 text-uppercase font-weight-bold black--text"
      v-if="
        postTypes.includes(post.type) &&
          post.type !== 'video' &&
          post.type !== 'approfondimento'
      "
    >
      <time>{{ $dayjs(post.date).format("LL") }} </time>
    </v-card-subtitle>
    <v-card-title
      class="card-description secondary--text pt-4 body-1 font-weight-bold d-flex align-start"
      v-html="post.title.rendered"
    ></v-card-title>
    <v-card-text
      v-if="postTypes.includes(post.type)"
      class="post-content pt-2 card-description-secondary body-2 font-weight-normal"
      >{{
        removeHTML(post.content.rendered || post.content.unrendered)
      }}</v-card-text
    >
    <v-spacer />
    <v-card-actions
      v-if="postTypes.includes(post.type)"
      class="post-actions ml-auto pa-4 justify-end align-center primary--text text-uppercase font-weight-bold"
    >
      {{ $t(`wordpress.${post.type}`) }}
      <v-icon color="primary">
        $chevronRight
      </v-icon>
    </v-card-actions>
  </v-card>
</template>
<style lang="scss" scoped>
// .card-description {
//   height: 95px;
// }
// .post-content {
//   height: 80px;
// }
.v-card {
  transition: border-color 0.3s ease;
  &__text,
  &__title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
  &__actions a {
    text-decoration: none;
  }
  &:hover {
    border: 1px solid $primary;
  }
}
</style>
<script>
export default {
  name: "PostCard",
  props: {
    post: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      postTypes: ["post", "approfondimento", "video"]
    };
  },
  methods: {
    removeHTML(html) {
      return html.replace(/<\/?[^>]+(>|$)/g, "");
    }
  },
  computed: {
    subtitle() {
      return this.post.type === "recipe" ? this.post.course : null;
    },
    routeName() {
      if (this.post.type === "recipe" || this.type === "recipe") {
        if (this.post.cuisine === "Appuntino") {
          return "AppuntinoDetail";
        } else if (this.post.cuisine === "Mixology") {
          return "MixologyDetail";
        }
        return "RecipeDetail";
      } else if (this.post.type === "appuntino" || this.type === "appuntino") {
        return "AppuntinoDetail";
      } else if (this.post.type === "mixology" || this.type === "mixology") {
        return "MixologyDetail";
      } else if (this.post.type === "video" || this.type === "video") {
        return "VideoDetail";
      } else if (
        this.post.type === "approfondimento" ||
        this.type === "approfondimento"
      ) {
        return "ApprofondimentoDetail";
      } else if (this.post.type === "post" || this.type === "post") {
        return "NewsDetail";
      } else return "Page";
    }
  }
};
</script>

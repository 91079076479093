var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"fill-height my-2 rounded-lg rounded-bl-0 d-flex flex-column",attrs:{"outlined":"","to":{
    name: _vm.routeName,
    params: { postName: _vm.post.slug, type: _vm.post.type }
  }}},[_c('v-img',{attrs:{"height":"190","max-height":"190","src":_vm.post.featured_image_url,"alt":`Post: ${_vm.post.title.rendered}`}}),(_vm.subtitle && _vm.subtitle.length)?_c('v-card-subtitle',{staticClass:"primary--text font-weight-bold text-uppercase"},[_c('v-chip',{attrs:{"small":"","color":"primary"}},[_vm._v(_vm._s(_vm.subtitle))])],1):_vm._e(),(
      _vm.postTypes.includes(_vm.post.type) &&
        _vm.post.type !== 'video' &&
        _vm.post.type !== 'approfondimento'
    )?_c('v-card-subtitle',{staticClass:"pt-4 pb-0 text-uppercase font-weight-bold black--text"},[_c('time',[_vm._v(_vm._s(_vm.$dayjs(_vm.post.date).format("LL"))+" ")])]):_vm._e(),_c('v-card-title',{staticClass:"card-description secondary--text pt-4 body-1 font-weight-bold d-flex align-start",domProps:{"innerHTML":_vm._s(_vm.post.title.rendered)}}),(_vm.postTypes.includes(_vm.post.type))?_c('v-card-text',{staticClass:"post-content pt-2 card-description-secondary body-2 font-weight-normal"},[_vm._v(_vm._s(_vm.removeHTML(_vm.post.content.rendered || _vm.post.content.unrendered)))]):_vm._e(),_c('v-spacer'),(_vm.postTypes.includes(_vm.post.type))?_c('v-card-actions',{staticClass:"post-actions ml-auto pa-4 justify-end align-center primary--text text-uppercase font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t(`wordpress.${_vm.post.type}`))+" "),_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" $chevronRight ")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import { render, staticRenderFns } from "./NewsList.vue?vue&type=template&id=466a54ed&scoped=true"
import script from "./NewsList.vue?vue&type=script&lang=js"
export * from "./NewsList.vue?vue&type=script&lang=js"
import style0 from "./NewsList.vue?vue&type=style&index=0&id=466a54ed&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "466a54ed",
  null
  
)

export default component.exports